import * as React from 'react'

const IndexPage = () => {
  return (
    <main>
      <title>TNH| Storytelling</title>
      <h1>The New Humanitarian</h1>
      <p>Welcome to our Storytelling Codebase.</p>
    </main>
  )
}

export default IndexPage
